<template>
  <div class="page">
    <section id="banner" class="align-middle">
    <banner-image-overlay></banner-image-overlay>
      <h2 class="title">Terms and Conditions</h2>
    </section>
    <div class="container 75%">
      <section class="row 200%">
        <p>These Terms and Conditions ("Terms") apply between you, entity or person
          accessing this website (including any possible sub-domain, unless specifically
          excluded by these own Terms), and MySpanishNow ("we", "us", "our"), the owner
          and operator of this website.
        </p>
        <p>The purpose of these Terms is to explain the terms and conditions of our
          services and products. We provide online Spanish lessons as well as resources
          for Spanish lessons.
        </p>
      </section>
      <section class="row 200%">
        <h3>To use our website you must:
        </h3>
        <ul>
          <li>be at least 18 years of age. In the scenario where one of our services, like
            online Spanish lessons, are for children or young people (under 18)
            the parents need to give their consent as well as, they are the ones responsible
            to access, create and manage their children account on our website</li>
          <li>provide accurate contact and billing information</li>
          <li>agree with these Terms as well as our <router-link :to="{ name: 'PrivacyPolicy' }">
          Privacy Policy</router-link></li>
        </ul>
        <p>By creating an account using our website, you accept these terms
          and conditions and warrant that you meet all the requirements listed in this statement,
          and that you won't use our website in a way that violates any laws or regulations.
        </p>
      </section>
      <section class="row 200%">
        <h3>General rules:
        </h3>
        <p>You must follow these rules:</p>
        <ul>
          <li>Your use of our website or any of the products and services that we provide,
            must comply with all applicable laws and regulations
          </li>
          <li>You are not allowed to use offensive, harassing, harmful, illegal or threatening,
            language or behaviour in our website or while using any of our services or
            products
          </li>
        </ul>
        <p>If you violate any of these rules, we may block you for having access to our products
          and services as well as suspend or terminate your account.
        </p>
      </section>
      <section class="row 200%">
        <h3>Email communications</h3>
        <p>You acknowledge and agree that MySpanishNow may send you emails related to
          your account.
        </p>
      </section>
      <section class="row 200%">
        <h3>Online Spanish Lessons
        </h3>
        <p>You are entitled of one free lesson for the first time. After that, if you decide
          to take online Spanish lessons with us, you always have to pay the lessons in advance.
          There are two types of payment for Spanish lessons:</p>
        <ul>
          <li><i>Packs</i>, types of payment where only a single payment covers all lessons
          that you selected to have with us
          </li>
          <li><i>Subscriptions</i>, types of payment where you agree to pay weekly or monthly
          for our online lessons. These Subscriptions can be cancelled via our website,
          by the client after the 11<sup>th</sup> successful consequent payment
          </li>
        </ul>
        <p>The rates of our online lessons can be viewed at
          <router-link :to="{ name: 'Payments' }">All Plans</router-link>.
          We may modify or change our fees and pricing
          at any time. Nevertheless, if you have a <i>Subscription</i> payment active that
          could be affected, by a price change, we will let you know in advance by
          email before the payment take effect.
        </p>
        <h4>Schedule a lesson</h4>
        <p>When you have an account in our website, you are able to schedule or re-schedule
          lessons. Nevertheless, a lesson needs to be accepted first by the teacher.
          If for some reason, the teacher rejected your lesson, you will get the chance to
          re-schedule it again.
        </p>
        <h4>Expiration</h4>
        <p>All purchased lessons have an expiration date of 6 months, from the moment that
          the payment have been successfully received.
        </p>
        <h4>What happens if you arrive late or you don't show up</h4>
        <p>We can wait up to 15 minutes from the beginning of the lesson.
          After that, we will take your lesson for granted.
        </p>
        <h4>Re-schedule a lesson</h4>
        <p>You may re-schedule a lesson if you notify us by email at hello@myspanishnow.com,
          24 hours in advance. Otherwise, the lesson would be taken for granted.
         </p>
        <h4>What happens if the teacher cancel your lesson</h4>
        <p>If the teacher cancel your lesson due to sickness or other personal problems, you
          have the following options:
        </p>
        <ul>
          <li>Re-schedule the cancelled lesson</li>
          <li>Ask for a total refund of the cancelled lesson</li>
        </ul>
      </section>
      <section class="row 200%">
        <h3>Spanish Resources</h3>
        <p>From time to time we may provide resources for Spanish lessons. These
          resources are targetted to help students or teachers.
        </p>
        <p>You are <b>allowed</b> to:</p>
        <ul>
          <li>Use those resources for personal use
          </li>
          <li>To use those resources in your lessons by mentioning us on at least
            one of our social networks (e.g. Facebook or LinkedIn)
          </li>
        </ul>
        <p>You are <b>not allowed</b> to:
        </p>
        <ul>
          <li>Redistribute the resources without our consent</li>
          <li>Modify the resources, partially or totally, without our consent</li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
import BannerImageOverlay from '../../components/common/utils/BannerImageOverlay.vue';

export default {
  name: 'TermsAndConditions',
  components: {
    BannerImageOverlay,
  },
};
</script>

<style scoped>
  .container {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .container section:last-child > :last-child {
    margin-bottom: 0;
  }

  section.row > ul {
    padding-left: 5em;
  }

  section.row > h3 {
      padding-left: 3em;
  }

  section.row > h4 {
      padding-left: 3.6em;
  }

  section.row > h5 {
      padding-left: 4.5em;
  }

  section > h3,
  section > h4,
  section > h5,
  section.row > ul {
    width: 100%;
  }

  @media screen and (max-width: 1280px) {
    section.row > h3 {
      padding-left: 2.2em;
    }

    section.row > h4 {
      padding-left: 2.65em;
    }

    section.row > h5 {
      padding-left: 3.3em;
    }
  }

  @media screen and (max-width: 736px) {
    section.row > h3 {
      padding-left: 1.88em;
    }

    section.row > h4 {
      padding-left: 2.25em;
    }

    section.row > h5 {
      padding-left: 2.75em;
    }
  }
</style>
